import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import { LinkBox } from 'src/components/atoms/LinkBox';
import { Section } from 'src/components/molecules/Section';
import { FixedImg } from 'src/embeds/image/image-dato';
import { useBlogLast3 } from 'src/hooks/use-blog-data';
import * as sys from '../../system';
import * as css from './drawer-latest-blogs.css';

const PostContainer = styled.div`
  display: grid;
  grid-gap: 1.75rem;
  max-width: 1240px;
  margin: 0 auto;

  text-align: left;

  @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
    grid-template-columns: 1fr 1fr 1fr;

    > *:nth-child(2) {
      position: relative;
      top: 3rem;
    }
  }
`;

const BlockLink = styled(LinkBox)`
  font-size: 87.5%;
`;

const PostMeta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ProfileImage = styled(FixedImg)`
  border-radius: 100%;
  overflow: hidden;
  margin-right: 0.5rem;
`;

const Author = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.primaryColor};
`;

const ContentContainer = styled.div`
  > p:last-child {
    margin-bottom: 0;
  }
`;

export default function Drawer() {
  const data = useBlogLast3();
  return (
    <section css={[css.section]}>
      <div css={[css.sectionHeader]}>
        <h2 css={[css.heading, css.hilite]}>From the blog</h2>
        <div>
          <Button to="/blog/">View all posts</Button>
        </div>
      </div>

      <PostContainer>
        {data.allDatoCmsPost.nodes.map((node) => {
          const primaryAuthor = node.authors[0];

          return (
            <BlockLink key={node.slug} link={`/blog/${node.slug}`}>
              <h3>{node.title}</h3>
              <PostMeta>
                {!!primaryAuthor?.image && (
                  <ProfileImage width={30} height={30} data={primaryAuthor?.image} />
                )}
                <span>
                  by <Author>{primaryAuthor?.name}</Author> on {node.meta.firstPublishedAt}
                </span>
              </PostMeta>
              <ContentContainer
                dangerouslySetInnerHTML={{
                  __html: node.excerptNode.childMarkdownRemark.html,
                }}
              />
            </BlockLink>
          );
        })}
      </PostContainer>
    </section>
  );
}
