import { css } from 'styled-components';
export * from '../updates/common.css';

export const section = css`
  margin: 72px 0;
  padding: 0;
`;

export const sectionHeader = css`
  text-align: center;
`;

export const heading = css`
  font-size: 36px;
`;
