import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/containers/Link';
const Container = styled(({ link, centered, ...props }) => {
  return link ? <Link to={link} {...props} /> : <div {...props} />;
})`
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(109, 112, 139, 0.1);
  background-color: #ffffff;
  color: inherit;
  text-decoration: none;
  transition: 0.2s ease box-shadow, 0.2s ease transform;

  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: 0 8px 16px 0 rgba(109, 112, 139, 0.1);
    transform: translateY(-8px);
  }
`;
const Content = styled.div`
  padding: 1.5rem;

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  > *:last-child {
    margin-bottom: 0;
  }
`;
export const LinkBox = ({ link, centered, children, ...props }) => (
  <Container link={link} {...props}>
    <Content centered={centered}>{children}</Content>
  </Container>
);
